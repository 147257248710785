require('./bootstrap');

import $ from 'jquery';
window.$ = window.jQuery = $;

import Swiper from 'swiper/bundle';
import CKeditor from '@ckeditor/ckeditor5-build-balloon';
import { Dropzone } from "dropzone";
import Chart from 'chart.js/auto';
window.Chart = Chart;
import 'datatables.net';

window.mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
window.mapboxgl.accessToken = 'pk.eyJ1IjoiYmx1ZWRvZ21pbGFubyIsImEiOiJja3NvbzFxZWYwMDEwMnJzMjNsemNjcWQzIn0.7cq-zTULTLJfrlr2Luztrg';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
window.MapboxGeocoder = MapboxGeocoder;

require('./controllers/TableController');
require('./controllers/UXController');

$(document).ready(function() {

    window.tbc = new TableController();
    window.uxc = new UXController();

});
 

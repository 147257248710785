TableController = function(el) {
    this.addListeners();
}

TableController.prototype.addListeners = function() {
    $(document).on('click', '.rowselector', function(e) {
        e.stopPropagation();
        const tr = $(this).closest('tr');
        let action;

        if ($(this).hasClass('fa-square')) {
            $(this).addClass('fa-check-square').removeClass('fa-square');
            action = 'select';
        } else {
            $(this).addClass('fa-square').removeClass('fa-check-square');
            action = 'deselect';
        }

        if (e.shiftKey) {
            const isafter = Utils.isBefore($('.lastclick').get(0), tr.get(0));
            let siblings;
            console.log('SHIFT CLICK', isafter);
            if (isafter) {
                siblings =  tr.prevUntil($('.lastclick'));
            } else {
                siblings =  tr.nextUntil($('.lastclick'));
            }

            if (action == 'select') {
                siblings.each(function() {
                    $(this).find('.rowselector').addClass('fa-check-square').removeClass('fa-square');
                });
            } else if (action == 'deselect') {
                siblings.each(function() {
                    $(this).find('.rowselector').addClass('fa-square').removeClass('fa-check-square');
                });
            } 
        }

        $('.lastclick').removeClass('lastclick');
        $(this).closest('tr').addClass('lastclick');

        var des_exist = $('.rowselector.fa-square').length > 0;
        var sel_exist = $('.rowselector.fa-check-square').length > 0;
        var sel_all = $('.rowselector.fa-check-square').length == $('.rowselector').length;
        var sel_none = $('.rowselector.fa-check-square').length == 0;

        if (des_exist && sel_exist && !sel_all) {
            $('.rowselectorall').addClass('fa-minus-square').removeClass('fa-check-square fa-square');
        } else if (sel_all) {
            $('.rowselectorall').addClass('fa-check-square').removeClass('fa-minus-square fa-square');
        } else if (sel_none) {
            $('.rowselectorall').addClass('fa-square').removeClass('fa-minus-square fa-check-square');
            $('.rowcommand.active').click();
        }
        
        if (!$('.rowselectorall').hasClass('fa-square')) {
            window.tbc.toggleRowCommands(false);
        } else {
            window.tbc.toggleRowCommands(true);
        }

        $('.rowcommands').removeClass('oneselected');
        if ($('.rowselector.fa-check-square').length == 1) {
            $('.rowcommands').addClass('oneselected');
        }
        $('.rowselectedcount span').text($('.rowselector.fa-check-square').length);
        
        var activeaction = $('.rowcommand.active').attr('data-rel');

        if (activeaction && typeof(window[activeaction+'_onchangeselection']) === 'function') {
            window[activeaction+'_onchangeselection']();
        }
    });
    
    $(document).on('click', '.rowselectorall', function(e) {
        e.stopPropagation();
        
        if ($(this).hasClass('fa-square')) {
            $(this).addClass('fa-check-square').removeClass('fa-square fa-minus-square');
            $('.rowselector').addClass('fa-check-square').removeClass('fa-square');
        } else {
            $(this).addClass('fa-square').removeClass('fa-check-square fa-minus-square');
            $('.rowselector').addClass('fa-square').removeClass('fa-check-square');
            $('.rowcommand.active').click();
        }
        
        if (!$('.rowselectorall').hasClass('fa-square')) {
            window.tbc.toggleRowCommands(false);
        } else {
            window.tbc.toggleRowCommands(true);
        }

        $('.rowcommands').removeClass('oneselected');
        if ($('.rowselector.fa-check-square').length == 1) {
            $('.rowcommands').addClass('oneselected');
        }
        $('.rowselectedcount span').text($('.rowselector.fa-check-square').length);
        
        var activeaction = $('.rowcommand.active').attr('data-rel');

        if (activeaction && typeof(window[activeaction+'_onchangeselection']) === 'function') {
            window[activeaction+'_onchangeselection']();
        }
    });
    
    $(document).on('click', '.rowcommand', function(e) {
        e.stopPropagation();
        if (!$(this).hasClass('active') && typeof $(this).attr('onclick') == typeof undefined) {
            var act = $(this).attr('data-rel');
            
            $('.rowcommand').removeClass('active');
            $(this).addClass('active');

            $('.rowaction').hide();
            $('.rowaction .report').hide();
            $('.rowaction .dispose').show();

            $('.rowaction.'+act).show();
            
            if (typeof(window[act+'_onshow']) === 'function') {
                window[act+'_onshow'](e);
            }
            
            $('.actionwrapper').show();
        } else {
            $('.rowcommand').removeClass('active');
            $('.rowaction').hide();
            $('.actionwrapper').hide();
        }
    });
}

TableController.prototype.finalizeRender = function() {
    $(".tablefilters").append($(".dataTables_filter"));
    $(".dataTables_wrapper").append($(".dataTables_length"));
    $(".tablecloser").append($(".dataTables_info"));
    $(".tablecloser").append($(".dataTables_paginate"));
    $(".tablecloser").append($(".dataTables_length"));
}

TableController.prototype.closeAction = function (hidecommands) {
    $('.rowcommand.active').trigger('click');
    //$('.rowcommand[data-rel="'+$(el).closest('.rowaction').classes()[1]+'"]').click();
    if (hidecommands) window.tbc.toggleRowCommands(true);
}

TableController.prototype.toggleRowCommands = function (dir) {
    if (dir) {
        $('.rowcommands').removeClass('visible');
    } else {
        $('.rowcommands').addClass('visible');
    }
}

TableController.prototype.disableRows = function (rows) {
    Object.keys(rows).map(function (key) { 
        const el = $('.tabledlist tr[data-id="'+rows[key]+'"]');
        el.addClass('disabled');
        el.find('.rowselector').removeClass('rowselector fa-check-square').addClass('rowselectordisabled fa-times-square');

        $('.rowselectedcount span').text($('.rowselector.fa-check-square').length);
        $('.rowselectorall').addClass('fa-square').removeClass('fa-check-square fa-minus-square');

        return rows[key]; 
    });
}

TableController.prototype.deleteRows = function (rows) {
    Object.keys(rows).map(function (key) { 
        const el = $('.tabledlist tr[data-id="'+rows[key]+'"]');
        el.addClass('disabled deleted');
        el.find('.rowselector').removeClass('rowselector fa-check-square').addClass('rowselectordisabled fa-times-square');
        $(el).remove();
        $('.dataTables_info').text('');
        $('.rowselectedcount span').text($('.rowselector.fa-check-square').length);
        $('.rowselectorall').addClass('fa-square').removeClass('fa-check-square fa-minus-square');

        return rows[key]; 
    });
}

TableController.prototype.toggleFilters = function (el) {
    $(el).toggleClass('tabbed');
    if ($(el).hasClass('tabbed')) {
        $('.tablefilters').show();
    } else {
        $('.tablefilters').hide();
    }

    return false;
}

TableController.prototype.getAllIds = function () {
    const allids = $('.rowselector.fa-square').map(function() {
        const tr = $(this).closest('tr');
        return tr.attr('data-id');
    }).toArray();

    return allids;
}

TableController.prototype.getSelectedIds = function () {
    const selectedids = $('.rowselector.fa-check-square').map(function() {
        const tr = $(this).closest('tr');
        return tr.attr('data-id');
    }).toArray();

    return selectedids;
}

TableController.prototype.getNotSelectedIds = function () {
    const notselectedids = $('.rowselector.fa-square').map(function() {
        const tr = $(this).closest('tr');
        return tr.attr('data-id');
    }).toArray();

    return notselectedids;
}

TableController.prototype.getFirstSelectedId = function () {
    const selectedids = window.tbc.getSelectedIds();
    
    return selectedids[0];
}

TableController.prototype.changeProduct = function (route, token, el) {
    window.tbc.getPrice(route, token, $(el).val(), $(el).closest('tr').attr('data-id'));
}

TableController.prototype.getAllPrices = function (route, token, product) {
    const allids = window.tbc.getAllIds();

    for (let i in allids) {
        window.tbc.getPrice(route, token, product, allids[i]);
    }
}

TableController.prototype.getPrice = function (route, token, product, plant) {
    const row = $('.tabledlist').find('tr[data-id="'+plant+'"]');
    const index = row.attr('data-index');
    const timingcel = row.find('td[data-rel="timing"]');
    timingcel.addClass('loading').empty();

    let options = {
        headers: {
            'X-CSRF-TOKEN': token,
            'Content-Type': "application/json"
        },
        type: 'GET',
        url: route+'/'+product+'/'+plant,
        success: function (j) {
            j = JSON.parse(j);
            
            let priceselect;
            if (j.data.length == 0) {
                priceselect = '<select name="order_lines['+index+'][priceId]" readonly><option value="">Request pricing quote</option></select>';
            } else {
                priceselect = '<select name="order_lines['+index+'][priceId]">';
                for (let i in j.data) {
                    priceselect += '<option value="'+j.data[i].id+'">'+j.data[i].version+'  ('+j.data[i].unitPrice+'€/'+j.data[i].unit+')</option>';
                }
                priceselect += '</select>';
            }
            timingcel.removeClass('loading').html(priceselect);
        },
        error: function (j) {
            timingcel.removeClass('loading').html('ko');
        }
    };

    $.ajax(options);
}

TableController.prototype.getOrderlines = function (route, token, order, labels) {
    const row = $('.tabledlist').find('tr[data-id="'+order+'"]');
    if (row.next().hasClass('child')) {
        row.next().remove();
        return;
    }
    
    const index = row.attr('data-index');
    $('tr.child').remove();

    labels = JSON.parse(labels);

    let options = {
        headers: {
            'X-CSRF-TOKEN': token,
            'Content-Type': "application/json"
        },
        type: 'GET',
        url: route+'/'+order,
        success: function (j) {
            j = JSON.parse(j);
            console.log('orderlines', j)
            const span = row.children('td').length;
            let sibling = '<tr class="child" data-parent="'+order+'"><td colspan="'+span+'"><table>';
            sibling += '<thead><tr><th>'+labels.plant+'</th><th>'+labels.product+'</th><th>'+labels.price+'</th><th>'+labels.power+'</th><th>'+labels.cost+'</th></tr></thead><tbody>';
            for (let i = 0; i < j.list.length; i++) {
                sibling += '<tr><td>'+j.list[i].systemName+'</td><td>'+j.list[i].productName+'</td><td>'+j.list[i].priceName+'</td><td>'+j.list[i].quantity+j.list[i].quantityUnit+'</td><td>'+parseInt(j.list[i].priceNoVat).toLocaleString()+'€</td></tr>';
            }
            sibling += '</tbody></table></td></tr>';
            row.after(sibling);
            console.log(j);
        },
        error: function (j) {
            console.log(j);
        }
    };

    $.ajax(options);
}

// ============================================================================
//    Author: Kenneth Perkins
//    Date:   Aug 28, 2020
//    Taken From: http://programmingnotes.org/
//    File:  Utils.js
//    Description: Javascript that handles general utility functions    
// ============================================================================
/**
* NAMESPACE: Utils
* USE: Handles general utility functions.
*/
var Utils = Utils || {};
(function(namespace) {
    'use strict';   
 
    // Property to hold public variables and functions
    let exposed = namespace;
    
    /**
    * FUNCTION: isBefore
    * USE: Determines if 'elementX' comes before 'elementY' in the DOM tree.
    * @param elementX: The first Javascript element to compare.
    * @param elementY: The second Javascript element to compare.
    * @param container: Optional. The container to limit the search.    
    * @return: True if 'elementX' comes before 'elementY', false otherwise.
    */    
    exposed.isBefore = (elementX, elementY, container = null) => {
        container = container || (elementX.parentNode === elementY.parentNode ? elementX.parentNode : null) || document;        
        let items = container.querySelectorAll('*');
        let result = false;
        for (const item of items) {
            if (item === elementX) {
                result = true;
                break;
            } else if (item === elementY) {
                result = false;
                break;
            }            
        }
        return result;
    }
    
    /**
    * FUNCTION: isAfter
    * USE: Determines if 'elementX' comes after 'elementY' in the DOM tree.
    * @param elementX: The first Javascript element to compare.
    * @param elementY: The second Javascript element to compare.
    * @param container: Optional. The container to limit the search.    
    * @return: True if 'elementX' comes after 'elementY', false otherwise.
    */      
    exposed.isAfter = (elementX, elementY, container = null) => {
        return !exposed.isBefore(elementX, elementY, container);
    }    

    (function (factory) {
        if (typeof define === 'function' && define.amd) {
            define([], factory);
        } else if (typeof exports === 'object') {
            module.exports = factory();
        }
    }(function() { return namespace; })); 
}(Utils)); // http://programmingnotes.org/
const { mapValues } = require("lodash");

UXController = function(el) {
    this.addListeners();
}

UXController.prototype.addListeners = function() {
    $(document).on('scroll', function() {
        if ($(window).scrollTop() == 0) {
            $('header').removeClass('scrolled');
        } else {
            $('header').addClass('scrolled');
        }
    });

    $(document).on('click', '.modalshader', function(e) {
        if (!$(e.target).hasClass('modalshader')) {
            return;
        }
        window.uxc.closeModal();
    });

    $(document).on('click', '.modalcloser', function(e) {
        window.uxc.closeModal();
    });

    $(document).on('click', '[data-role="scopeselector"]', function(e) {
        $('[data-role="scopeselector"]').removeClass('selected');
        $(this).addClass('selected');
        let token = $(this).siblings('input').val();
        let route = $(this).parent().attr('data-ep');

        let options = {
            headers: {
                'X-CSRF-TOKEN': token,
                'Content-Type': "application/json"
            },
            type: 'POST',
            url: route,
            data: $(this).attr('data-id'),
            success: function (j) {
                location.reload();
            },
            error: function (j) {
                console.log("error", j);
            }
        };        
    
        $.ajax(options);
    });
}


UXController.prototype.startFunnel = function(url) {
    const si = btoa(unescape(encodeURIComponent(JSON.stringify(window.tbc.getSelectedIds()))));
    window.location.href = url+'/0/'+si;
}

UXController.prototype.loadModal = function (route, token, data) {

    if (typeof data === 'function') {
        data = {'data': data()};
    }

    let options = {
        headers: {
            'X-CSRF-TOKEN': token,
            'Content-Type': "application/json"
        },
        type: 'GET',
        url: route,
        success: function (html) {
            window.uxc.renderModal(html);
        },
        error: function (j) {
            console.log("error", j);
        }
    };

    if (data) {
        options.data = data;
    }

    $.ajax(options);
}

UXController.prototype.renderModal = function (html) {
    let mw = $('.modalshader');
    if (mw.length == 0) {
        $('body').append('<div class="modalshader"><div class="modalwrapper"><div class="modalmask"></div><div class="modalcloser fal fa-times"></div></div></div>');
        mw = $('.modalshader');
    }
    $('body').addClass('modaled');
    mw.find('.modalmask').empty().append(html);
    mw.show();
}


UXController.prototype.closeModal = function () {
    let mw = $('.modalshader');
    $('.modalwrapper').removeClass('autoheight');
    $('body').removeClass('modaled');
    mw.hide();
}

UXController.prototype.toggleViewType = function(el, route, token) {
    console.log('toggleViewType', route);
    if ($('.tablewrapper').is(':visible')) {
        $('.tablewrapper').hide();
        $('.tablecloser').hide();
        $('.mapwrapper').addClass('visible');
        $(el).html('<i class="fa-light fa-bars"></i>'+$(el).attr('data-labellist'));

        if ($('.mapwrapper').is(':empty')) {
            let options = {
                headers: {
                    'X-CSRF-TOKEN': token,
                    'Content-Type': "application/json"
                },
                type: 'GET',
                url: route,
                success: function (html) {
                    $('.mapwrapper').html(html);
                },
                error: function (j) {
                    console.log("error", j);
                }
            };
            $.ajax(options);
        }
    } else {
        $('.tablewrapper').show();
        $('.tablecloser').show();
        $('.mapwrapper').removeClass('visible');
        $(el).html('<i class="fa-light fa-map"></i>'+$(el).attr('data-labelmap'));
    }
}
